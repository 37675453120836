<template>
	<div>
		<h1><em class="fas fa-sign-out-alt"></em> Logout</h1>

		<div class="text-center" v-show="!done">Logging Out...</div>
		<div class="text-center" v-show="done">You have been logged out. <router-link to="/login">Login Again</router-link></div>
	</div>
</template>

<script>
export default
{
	name: 'Home',
	data: function()
	{
		return {
			done: false
		}
	},
	mounted: function()
	{
		this.logout();
	},
	methods:
	{
		logout: function()
		{
			var vm = this;

			this.CORS("GET", "/logout", null,
			function()
			{
				vm.done = true;
			}, function(response)
			{
				vm.showError("Error Logging Out", response.responseText, true, null);
			})
		}
	}
}
</script>
